const data = [
  {
    id: '1',
    title: 'Fintech IOS App',
    description:
      'A mobile e-wallet and remittance app with SwiftUI featuring secure biometric authentication, user sign-in, transaction tracking, and low-fee global transfers.',
    image: {
      src: 'https://ik.imagekit.io/29m7u1ujl/email-images/myprojects/IMG_0558.PNG?updatedAt=1720354315158',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: '',
    },
    technologies: [
      '#swift',
      '#swiftui',
      '#alamofire',
      '#xcode',
      '#spring-boot',
    ],
  },
  {
    id: '2',
    title: 'Wallet and Remittence Web Application',
    description:
      'A web-based e-wallet and remittance platform built with Angular and Spring Boot, featuring secure user authentication, real-time transaction tracking, and low-fee international money transfers.',
    image: {
      src: 'https://ik.imagekit.io/29m7u1ujl/email-images/myprojects/site.jpg?updatedAt=1720354419975',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://apps.apple.com/us/app/minfin-currency-rates/id1574863878',
    },
    technologies: [
      '#typescript',
      '#angular',
      '#spring-boot',
      '#rabbitmq',
    ],
  },
  {
    id: '3',
    title: 'Property Management Website',
    description:
      'A real estate web application built with ReactJS and Spring Boot, featuring property listings, user authentication, advanced search filters, and real-time notifications.',
    image: {
      src: 'https://ik.imagekit.io/29m7u1ujl/email-images/myprojects/1.png?updatedAt=1720355432399',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      '#javascript',
      '#reactjs',
      '#spring-boot',
    ],
  },
  {
    id: '4',
    title: 'Real Estate ChatBot',
    description:
      'A real estate chatbot built with Python, Twilio, and Django, offering property inquiries, scheduling visits, and real-time notifications through whatsapp.',
    image: {
      src: '',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      '#python',
      '#twilio',
 
    ],
  },
  {
    id: '5',
    title: 'Logistics Website',
    description:
      'A logistics web app built with HTML, CSS, and JavaScript, featuring real-time tracking, shipment management, and delivery status updates.',
    image: {
      src: 'https://ik.imagekit.io/29m7u1ujl/email-images/myprojects/2.png?updatedAt=1720355431005',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://logistics-o4kq.onrender.com/',
      repo: '',
    },
    technologies: [
      '#react',
      '#nodejs',
      '#express',
      '#mongodb',
      '#react-query',
      '#react-transition-group',
      '#mongoose',
      '#jwt',
      '#aws',
    ],
  },
  {
    id: '6',
    title: 'Phishing Detection WebApp',
    description:
      'A phishing detection system leveraging deep learning to analyze images and URLs, providing robust protection against malicious sites and phishing attacks.',
    image: {
      src: 'https://ik.imagekit.io/29m7u1ujl/email-images/myprojects/4.png?updatedAt=1720355425121',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      '#python',
      '#flusk',
      '#tensorflow',
      '#lstm',
      '#cnn',
    ],
  },
  {
    id: '7',
    title: 'Real Estate Application',
    description:
      'A mobile application with assist both landlords and tenants in listing and finding properties',
    image: {
      src: 'https://ik.imagekit.io/29m7u1ujl/email-images/myprojects/Simulator%20Screenshot%20-%20iPhone%2015%20Plus%20-%202024-12-27%20at%2011.44.03.png?updatedAt=1735292779961',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: '',
      repo: '',
    },
    technologies: [
      '#python',
      '#django',
      '#react native',
      '#typescript',
    ],
  },

  {
    id: '8',
    title: 'Fintech Android App',
    description:
      'A mobile e-wallet and remittance app with SwiftUI featuring secure biometric authentication, user sign-in, transaction tracking, and low-fee global transfers.',
    image: {
      src: 'https://ik.imagekit.io/29m7u1ujl/email-images/myprojects/Screenshot_20241227_115419_Tactplan%20Financial%20Services.jpg?updatedAt=1735293716428',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: '',
    },
    technologies: [
      '#kotlin',
      '#jetpack compose',
      '#android studio',
      '#spring-boot',
    ],
  },

];

export default data;
